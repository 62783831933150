import { useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { WarehouseSchema } from "../interfaces/user.interface";

const HARDCODED_VALUES: Array<WarehouseSchema> = [
  {
    id: 1,
    warehouseName: "Drakelow",
    warehouseShortCode: "KD",
    warehouseVisionCode: "CULTKD",
    address: {
      id: 1,
      address: "AB Street No 37",
      postCode: "323-3466",
      city: "Ketterdam",
      country: "Ravka",
      createdAt: "2022-09-22T09:08:37.006Z",
      updatedAt: "2022-09-22T09:08:37.006Z",
    },
  },
  {
    id: 3,
    warehouseName: "Melksham",
    warehouseShortCode: "MM",
    warehouseVisionCode: "CULTMM",
    address: {
      id: 2,
      address: "Little Palace Street No 1",
      postCode: "098-2131",
      city: "Os Alta",
      country: "Ravka",
      createdAt: "2022-09-22T09:08:37.006Z",
      updatedAt: "2022-09-22T09:08:37.006Z",
    },
  },
  {
    id: 25,
    warehouseName: "Coterie - CA",
    warehouseShortCode: "LW",
    warehouseVisionCode: "CULTCA",
    address: {
      id: 4,
      address: "Port One Logistics Park, Unit 6, Blackacre Road",
      postCode: "IP6 0FL",
      city: "Ipswich",
      country: "United Kingdom",
      createdAt: "2024-06-19 10:41:13.237707",
      updatedAt: "2024-06-19 10:41:13.237707",
    },
  },
  {
    id: 26,
    warehouseName: "Coterie - CV",
    warehouseShortCode: "LW",
    warehouseVisionCode: "CULTCV",
    address: {
      id: 4,
      address: "Port One Logistics Park, Unit 6, Blackacre Road",
      postCode: "IP6 0FL",
      city: "Ipswich",
      country: "United Kingdom",
      createdAt: "2024-06-19 10:41:13.237707",
      updatedAt: "2024-06-19 10:41:13.237707",
    },
  },
];

function useWarehouses(hardcoded: boolean) {
  if (hardcoded) {
    return { isLoading: false, data: HARDCODED_VALUES };
  }

  const client = useClient<Array<WarehouseSchema>>();
  const result = useQuery({
    queryKey: ["warehouses"],
    queryFn: () => client(`warehouses`, {}).then((data) => data),
  });

  return { isLoading: result.isLoading, data: result.data };
}

export { useWarehouses };
