import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "4%",
    text: strings.document_reference,
    key: "documentRef",
    disableInput: true,
  },
  {
    width: "4%",
    text: strings.po_number,
    key: "purchaseOrderNumber",
  },
  {
    width: "4%",
    text: strings.value,
    key: "value",
    disableInput: true,
    type: "number",
  },
  {
    width: "4%",
    text: strings.qtyCases,
    key: "quantity",
    disableInput: true,
  },
  {
    width: "4%",
    text: strings.warehouse,
    key: "warehouseShortCode",
  },
  {
    width: "4%",
    text: strings.expected_delivery_date,
    key: "expectedDeliveryDate",
    datePicker: true,
  },
  {
    width: "8%",
    text: strings.supplier,
    key: "supplierName",
  },
  {
    width: "4%",
    text: strings.status,
    key: "status",
    disableInput: true,
  },
];

export const emptyCells = {
  expandableCell: {
    width: "1%",
  },
  firstCell: {
    width: "1%",
  },
  lastCell: {
    width: "1%",
  },
};
