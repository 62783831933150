import React from "react";
import { Box, IconButton, Snackbar, makeStyles, withStyles } from "@material-ui/core";
import { Publish, Label } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useState } from "react";
import { types, strings } from "common";
import { useCasesExport } from "../../utils/cases";
import { MainButton } from "../Common";
import CreatePreAdviceModal from "./CreatePreAdviceModal";
import CreateSaleOrder from "./CreateSaleOrder";
import { casesStatusList } from "common/constants";
import { LocalShipping } from "@material-ui/icons";
import { CaseDataSchema } from "../../interfaces/case.interface";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  rightButton: { marginLeft: theme.spacing(4) },
}));

interface ExportButtonsProps {
  selectedrows: Array<CaseDataSchema>;
  totalCWPurchasePrice?: number;
  deSelectAll: () => void;
  name: string;
}

const ExportButtons = ({ selectedrows, deSelectAll, totalCWPurchasePrice, name, ...props }: ExportButtonsProps) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [openPreAdvice, setOpenPreAdvice] = useState(false);
  const [openSaleOrder, setOpenSaleOrder] = useState(false);

  const caseIds = selectedrows.map((row) => row.id);

  const downloadStockExport = useCasesExport({ caseIds }, name === "stock" ? "asset-units" : "physical-cases");

  const handleExportStock = () => {
    downloadStockExport();
  };

  return (
    <>
      <ButtonRow>
        <MainButton id="stock_export" {...props} startIcon={<Publish />} onClick={handleExportStock}>
          {strings.export}
        </MainButton>

        <MainButton
          id="stock_create_pre_advice"
          {...props}
          startIcon={<Label />}
          className={`${classes.rightButton}`}
          onClick={() => setOpenPreAdvice(true)}
          disabled={
            selectedrows.length === 0 ||
            !selectedrows.every((el) => el.purchaseOrderNumber === selectedrows[0]?.purchaseOrderNumber)
          }
        >
          {strings.create_pre_advice.toUpperCase()}
        </MainButton>

        <MainButton
          id="stock_ship"
          {...props}
          startIcon={<LocalShipping />}
          className={`${classes.rightButton}`}
          onClick={() => setOpenSaleOrder(true)}
          disabled={
            selectedrows.length === 0 ||
            selectedrows.some((el) => {
              return !el.deliveryRequestId || el.status !== casesStatusList.IN_STOCK || !el.physicalCaseId;
            }) ||
            !selectedrows.every((el) => el.warehouseShortCode === selectedrows[0]?.warehouseShortCode)
          }
        >
          {strings.ship.toUpperCase()}
        </MainButton>

        {openPreAdvice && (
          <CreatePreAdviceModal
            open={openPreAdvice}
            setOpen={setOpenPreAdvice}
            selectedrows={selectedrows}
            deSelectAll={deSelectAll}
            setErrorModal={setErrorMessage}
            totalCWPurchasePrice={totalCWPurchasePrice}
          />
        )}
        {openSaleOrder && (
          <CreateSaleOrder
            open={openSaleOrder}
            setOpen={setOpenSaleOrder}
            selectedRows={selectedrows}
            isFromDeal={false}
            setErrorModal={setErrorMessage}
          />
        )}
      </ButtonRow>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        message={errorMessage}
        action={
          <React.Fragment>
            <IconButton onClick={() => setErrorMessage(null)} size="small" aria-label="close" color="inherit">
              <CloseIcon fontSize="small" color="error" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>
    </>
  );
};

ExportButtons.propTypes = {
  selectedrows: PropTypes.arrayOf(types.stockDataShape).isRequired,
  deSelectAll: PropTypes.func,
};

export default ExportButtons;
